import React from "react";
import banner from "@/static/images/banner.png";
import store_img_1 from "@/static/images/store_img_1.png";
import store_img_2 from "@/static/images/store_img_2.png";
import store_img_3 from "@/static/images/store_img_3.png";
import store_img_4 from "@/static/images/store_img_4.png";
import function_icon_1 from "@/static/images/function_icon_1.png";
import function_icon_2 from "@/static/images/function_icon_2.png";
import function_icon_3 from "@/static/images/function_icon_3.png";
import function_icon_4 from "@/static/images/function_icon_4.png";
import function_icon_5 from "@/static/images/function_icon_5.png";
import function_icon_6 from "@/static/images/function_icon_6.png";
import function_icon_7 from "@/static/images/function_icon_7.png";
import function_icon_8 from "@/static/images/function_icon_8.png";
import product_icon_1 from "@/static/images/product_icon_1.png";
import product_icon_2 from "@/static/images/product_icon_2.png";
import product_icon_3 from "@/static/images/product_icon_3.png";
import product_icon_4 from "@/static/images/product_icon_4.png";
import product_icon_5 from "@/static/images/product_icon_5.png";
import product_icon_6 from "@/static/images/product_icon_6.png";
import hardware_pic_1 from "@/static/images/hardware_pic_1.png";
import hardware_pic_2 from "@/static/images/hardware_pic_2.png";
import hardware_pic_3 from "@/static/images/hardware_pic_3.png";
import equipment_pic_1 from "@/static/images/equipment_pic_1.png";
import equipment_pic_2 from "@/static/images/equipment_pic_2.png";
import equipment_pic_3 from "@/static/images/equipment_pic_3.png";
import equipment_pic_4 from "@/static/images/equipment_pic_4.png";
import equipment_pic_5 from "@/static/images/equipment_pic_5.png";
import equipment_pic_6 from "@/static/images/equipment_pic_6.png";
export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: "https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg",
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item1",
        className: "header0-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <p>免费试用</p>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
      {
        name: "item2",
        className: "header0-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <p>登录</p>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
};
export const Banner00DataSource = {
  wrapper: { className: "banner0 m0kmhvs0ssk-editor_css" },
  content: {
    className: "banner0-content",
    children: (
      <div className="banner-content">
        <img src={banner} className="banner" alt="" />
      </div>
    ),
  },
  button: {
    className: "banner0-button",
    children: (
      <span>
        <p>
          <br />
        </p>
      </span>
    ),
  },
};
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>适用店铺</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: store_img_1,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>商超</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: store_img_2,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>便利店</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: store_img_3,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>零食店</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block~m0kn3pn5lbm",
        className: "content0-block",
        md: 6,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: store_img_4,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>生鲜店</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5 m0knj72fmdl-editor_css" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>核心业务</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_1,
          },
          content: {
            children: (
              <span>
                <p>商品管理</p>
              </span>
            ),
            className: "m0knjo3qado-editor_css",
          },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_2,
          },
          content: {
            children: (
              <span>
                <p>店铺管理</p>
              </span>
            ),
            className: "m0knjva66n-editor_css",
          },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_3,
          },
          content: {
            children: (
              <span>
                <p>会员管理</p>
              </span>
            ),
            className: "m0knlt077oj-editor_css",
          },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_4,
          },
          content: {
            children: (
              <span>
                <p>促销管理</p>
              </span>
            ),
            className: "m0knlxi6hrs-editor_css",
          },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_5,
          },
          content: {
            children: (
              <span>
                <p>扫码收款</p>
              </span>
            ),
            className: "m0knmmrknq-editor_css",
          },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_6,
          },
          content: {
            children: (
              <span>
                <span>
                  <p>经营分析</p>
                </span>
              </span>
            ),
            className: "m0knn2o6d79-editor_css",
          },
        },
      },
      {
        name: "block6",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_7,
          },
          content: {
            children: (
              <span>
                <span>
                  <p>连锁管理</p>
                </span>
              </span>
            ),
            className: "m0knn3nbb3-editor_css",
          },
        },
      },
      {
        name: "block7",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: function_icon_8,
          },
          content: {
            children: (
              <span>
                <p>员工管理</p>
              </span>
            ),
            className: "m0knncoxjg7-editor_css",
          },
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: {
    className: "home-page-wrapper content0-wrapper m0kolj4mk9-editor_css",
  },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>产品特色</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content1-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: product_icon_1,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>软件安装</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>无需安装 注册即用</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content1-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: product_icon_2,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>部署</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>快捷部署 稳定高效</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content1-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: product_icon_3,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>维护</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>维护简单 运营成本低</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block~m0koaz9snbf",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content1-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: product_icon_4,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>投入</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>低投入 高回报</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block~m0kob0e04y",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content1-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: product_icon_5,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>数据安全</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>据自动上传云端数据库断网也不会丢失</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block~m0kob0z4t6",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content1-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: product_icon_6,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>操作体验</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>支持多点触屏 反映灵敏界面简洁 操作方便</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: "home-page-wrapper content12-wrapper" },
  page: { className: "home-page content12" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>完美适配多种硬件</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content~m0kov5gwtoi",
        className: "m0kov6bz04-editor_css",
        children: (
          <span>
            <span>
              <p>支持Windows 安卓系统</p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "img-wrapper",
    children: [
      {
        name: "block0",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children: hardware_pic_1,
            className: "m0koweembp-editor_css",
          },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children: hardware_pic_2,
          },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children: hardware_pic_3,
          },
        },
      },
    ],
  },
};
export const Content51DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>兼容多种外接设备</p>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: equipment_pic_1,
          },
          content: {
            children: (
              <span>
                <span>
                  <p>扫码枪</p>
                </span>
              </span>
            ),
            className: "m0kp6krw15-editor_css",
          },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: {
            className: "content5-block-content m0kp2mra7mc-editor_css",
          },
          img: {
            children: equipment_pic_2,
          },
          content: {
            children: (
              <span>
                <span>
                  <p>客显屏</p>
                </span>
              </span>
            ),
            className: "m0kp6qzp2p-editor_css",
          },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: {
            className: "content5-block-content m0kp2xkk6hi-editor_css",
          },
          img: {
            children: equipment_pic_3,
          },
          content: {
            children: (
              <span>
                <p>钱箱</p>
              </span>
            ),
            className: "m0kp5d64jur-editor_css",
          },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: equipment_pic_4,
          },
          content: {
            children: (
              <span>
                <span>
                  <p>小票打印机</p>
                </span>
              </span>
            ),
            className: "m0kp6611xh7-editor_css",
          },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: equipment_pic_5,
          },
          content: {
            children: (
              <span>
                <span>
                  <p>标签打印机</p>
                </span>
              </span>
            ),
            className: "m0kp75wsbfn-editor_css",
          },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: equipment_pic_6,
          },
          content: {
            children: (
              <span>
                <p>电子称</p>
              </span>
            ),
            className: "m0kp7akkztd-editor_css",
          },
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children: "https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: {
    className: "content1-title",
    children: (
      <span>
        <span>
          <p>成都熊掌柜科技有限责任公司</p>
        </span>
      </span>
    ),
  },
  content: {
    className: "content1-content",
    children: (
      <span>
        <span>
          <p>地址：成都市高新区天华一路99号天府软件园B5</p>
          <p>邮编：610000</p>
          <p>咨询电话</p>
          <p>19180793034</p>
        </span>
      </span>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: { className: "home-page-wrapper footer0-wrapper" },
  OverPack: { className: "home-page footer0", playScale: 0.05 },
  copyright: {
    className: "copyright",
    children: (
      <span>
        <span>
          Copyright © All Rights Reserved. 成都熊掌柜科技有限责任公司
          蜀ICP备2020029903号-5&nbsp;
          <br />
        </span>
      </span>
    ),
  },
};
