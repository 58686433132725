import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import phone from "@/static/images/phone.png";

function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? "bottom" : "right",
    one: isMobile
      ? {
          scaleY: "+=0.3",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        }
      : {
          x: "-=30",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <p className="address">地址：成都市高新区天华一路99号天府软件园B5</p>
          <p className="addressCode">邮编：610000</p>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={["easeOutQuad", "easeInQuad"]}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <div key="p" {...dataSource.content} className="xzg-company">
            {/* {dataSource.content.children} */}
            <img className="icon" src={phone} alt="" />
            <div className="phone">
              <p>咨询电话</p>
              <p>19180793034</p>
            </div>
          </div>
          {/* <span {...dataSource.img}> */}
          {/* <img src={dataSource.img.children} width="100%" alt="img" /> */}
          {/* </span> */}
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
